import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto jwt sign`}</strong>{` -- create a signed JWT data structure`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto jwt sign [- | <filename>]
[--alg=<algorithm>] [--aud=<audience>] [--iss=<issuer>] [--sub=<sub>]
[--exp=<expiration>] [--iat=<issued_at>] [--nbf=<not-before>]
[--key=<file>] [--jwks=<jwks>] [--kid=<kid>] [--jti=<jti>]
[--header=<key=value>] [--password-file=<file>]
[--x5c-cert=<file>] [--x5c-key=<file>] [--x5c-insecure]
[--x5t-cert=<file>] [--x5t-key=<file>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto jwt sign`}</strong>{` command generates a signed JSON Web Token (JWT) by
computing a digital signature or message authentication code for a JSON
payload. By default, the payload to sign is read from STDIN and the JWT will
be written to STDOUT. The suggested pronunciation of JWT is the same as the
English word "jot".`}</p>
    <p>{`A JWT is a compact data structure used to represent some JSON encoded "claims"
that are passed as the payload of a JWS or JWE structure, enabling the claims
to be digitally signed and/or encrypted. The "claims" (or "claim set") are
represented as an ordinary JSON object. JWTs are represented using a compact
format that's URL safe and can be used in space-constrained environments. JWTs
can be passed in HTTP Authorization headers and as URI query parameters.`}</p>
    <p>{`A "claim" is a piece of information asserted about a subject, represented as a
key/value pair. Logically a verified JWT should be interpreted as "`}<inlineCode parentName="p">{`issuer`}</inlineCode>{` says
to `}<inlineCode parentName="p">{`audience`}</inlineCode>{` that `}<inlineCode parentName="p">{`subject`}</inlineCode>{`'s `}<inlineCode parentName="p">{`claim-name`}</inlineCode>{` is `}<inlineCode parentName="p">{`claim-value`}</inlineCode>{`" for each claim.`}</p>
    <p>{`Some optional arguments introduce subtle security considerations if omitted.
These considerations should be carefully analyzed. Therefore, omitting `}<inlineCode parentName="p">{`subtle`}</inlineCode>{`
arguments requires the use of the `}<strong parentName="p">{`--subtle`}</strong>{` flag as a misuse prevention
mechanism.`}</p>
    <p>{`A JWT signed using JWS has three parts:`}</p>
    <pre><code parentName="pre" {...{}}>{`1. A base64 encoded JSON object representing the JOSE (JSON Object Signing
   and Encryption) header that describes the cryptographic operations
   applied to the JWT Claims Set
2. A base64 encoded JSON object representing the JWT Claims Set
3. A base64 encoded digital signature of message authentication code
`}</code></pre>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto jwt`}</strong>{`.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--alg`}</strong>{`=`}<inlineCode parentName="p">{`algorithm`}</inlineCode>{`, `}<strong parentName="p">{`--algorithm`}</strong>{`=`}<inlineCode parentName="p">{`algorithm`}</inlineCode>{`
The signature or MAC algorithm to use. Algorithms are case-sensitive strings
defined in RFC7518. The selected algorithm must be compatible with the key
type. This flag is optional. If not specified, the "alg" member of the JWK is
used. If the JWK has no "alg" member then a default is selected depending on
the JWK key type. If the JWK has an "alg" member and the "alg" flag is passed
the two options must match unless the '--subtle' flag is also passed.`}</p>
    <p><inlineCode parentName="p">{`algorithm`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`HS256`}</strong>{`: HMAC using SHA-256 (default for "oct" key type)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`HS384`}</strong>{`: HMAC using SHA-384`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`HS512`}</strong>{`: HMAC using SHA-512`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RS256`}</strong>{`: RSASSA-PKCS1-v1_5 using SHA-256 (default for "RSA" key type)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RS384`}</strong>{`: RSASSA-PKCS1-v1_5 using SHA-384`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RS512`}</strong>{`: RSASSA-PKCS1-v1_5 using SHA-512`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ES256`}</strong>{`: ECDSA using P-256 and SHA-256 (default for "EC" key type)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ES384`}</strong>{`: ECDSA using P-384 and SHA-384`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ES512`}</strong>{`: ECDSA using P-521 and SHA-512`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PS256`}</strong>{`: RSASSA-PSS using SHA-256 and MGF1 with SHA-256`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PS384`}</strong>{`: RSASSA-PSS using SHA-384 and MGF1 with SHA-384`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PS512`}</strong>{`: RSASSA-PSS using SHA-512 and MGF1 with SHA-512`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`EdDSA`}</strong>{`: EdDSA signature algorithm`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--iss`}</strong>{`=`}<inlineCode parentName="p">{`issuer`}</inlineCode>{`, `}<strong parentName="p">{`--issuer`}</strong>{`=`}<inlineCode parentName="p">{`issuer`}</inlineCode>{`
The issuer of this JWT. The processing of this claim is generally
application specific. Typically, the issuer must match the name of some
trusted entity (e.g., an identity provider like "`}<a parentName="p" {...{
        "href": "https://accounts.google.com%22"
      }}>{`https://accounts.google.com"`}</a>{`)
and identify which key(s) to use for JWT verification and/or decryption (e.g.,
the keys at "`}<a parentName="p" {...{
        "href": "https://www.googleapis.com/oauth2/v3/certs%22"
      }}>{`https://www.googleapis.com/oauth2/v3/certs"`}</a>{`).`}</p>
    <p><inlineCode parentName="p">{`issuer`}</inlineCode>{` is a case-sensitive string.`}</p>
    <p><strong parentName="p">{`--aud`}</strong>{`=`}<inlineCode parentName="p">{`audience`}</inlineCode>{`, `}<strong parentName="p">{`--audience`}</strong>{`=`}<inlineCode parentName="p">{`audience`}</inlineCode>{`
The intended recipient(s) of the JWT, encoded as the `}<strong parentName="p">{`"aud"`}</strong>{` claim in the
JWT. Recipient(s) must identify themselves with one or more of the values in
the `}<strong parentName="p">{`"aud"`}</strong>{` claim. The `}<strong parentName="p">{`"aud"`}</strong>{` claim can be a string (indicating a single
recipient) or an array (indicating multiple potential recipients). This flag
can be used multiple times to generate a JWK with multiple intended
recipients.`}</p>
    <p>{`Each `}<inlineCode parentName="p">{`audience`}</inlineCode>{` is a case-sensitive string.`}</p>
    <p><strong parentName="p">{`--sub`}</strong>{`=`}<inlineCode parentName="p">{`subject`}</inlineCode>{`, `}<strong parentName="p">{`--subject`}</strong>{`=`}<inlineCode parentName="p">{`subject`}</inlineCode>{`
The subject of this JWT. The "claims" are normally interpreted as statements
about this subject. The subject must either be locally unique in the context
of the issuer or globally unique. The processing of this claim is generally
application specific.`}</p>
    <p><inlineCode parentName="p">{`subject`}</inlineCode>{` is a case-sensitive string.`}</p>
    <p><strong parentName="p">{`--exp`}</strong>{`=`}<inlineCode parentName="p">{`expiration`}</inlineCode>{`, `}<strong parentName="p">{`--expiration`}</strong>{`=`}<inlineCode parentName="p">{`expiration`}</inlineCode>{`
The expiration time on or after which the JWT must not be accepted.
`}<inlineCode parentName="p">{`expiration`}</inlineCode>{` must be a numeric value representing a Unix timestamp.`}</p>
    <p><strong parentName="p">{`--nbf`}</strong>{`=`}<inlineCode parentName="p">{`not-before`}</inlineCode>{`, `}<strong parentName="p">{`--not-before`}</strong>{`=`}<inlineCode parentName="p">{`not-before`}</inlineCode>{`
The time before which the JWT must not be accepted. `}<inlineCode parentName="p">{`not-before`}</inlineCode>{` must be a
numeric value representing a Unix timestamp. If not provided, the current time
is used.`}</p>
    <p><strong parentName="p">{`--iat`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`, `}<strong parentName="p">{`--issued-at`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
The time at which the JWT was issued, used to determine the age of the JWT.
ISSUED_AT must be a numeric value representing a Unix timestamp. If not
provided, the current time is used.`}</p>
    <p><strong parentName="p">{`--jti`}</strong>{`=`}<inlineCode parentName="p">{`jti`}</inlineCode>{`, `}<strong parentName="p">{`--jwt-id`}</strong>{`=`}<inlineCode parentName="p">{`jti`}</inlineCode>{`
A unique identifier for the JWT. The identifier must be assigned in a manner
that ensures that there is a negligible probability that the same value will
be accidentally assigned to multiple JWTs. The JTI claim can be used to
prevent a JWT from being replayed (i.e., recipient(s) can use `}<inlineCode parentName="p">{`jti`}</inlineCode>{` to make a
JWT one-time-use). The `}<inlineCode parentName="p">{`jti`}</inlineCode>{` argument is a case-sensitive string. If the
`}<strong parentName="p">{`--jti`}</strong>{` flag is used without an argument a `}<inlineCode parentName="p">{`jti`}</inlineCode>{` will be generated randomly
with sufficient entropy to satisfy the collision-resistance criteria.`}</p>
    <p><strong parentName="p">{`--header`}</strong>{`=`}<inlineCode parentName="p">{`key=value`}</inlineCode>{`
The `}<inlineCode parentName="p">{`key=value`}</inlineCode>{` used as a header in the JWT token. Use the flag multiple
times to set multiple headers.`}</p>
    <p><strong parentName="p">{`--key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`, `}<strong parentName="p">{`--x5c-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`, `}<strong parentName="p">{`--x5t-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the key with which to sign the JWT.
JWTs can be signed using a private JWK (or a JWK encrypted as a JWE payload) or
a PEM encoded private key (or a private key encrypted using the modes described
on RFC 1423 or with PBES2+PBKDF2 described in RFC 2898).`}</p>
    <p><strong parentName="p">{`--jwks`}</strong>{`=`}<inlineCode parentName="p">{`jwks`}</inlineCode>{`
The JWK Set containing the key to use to sign the JWT. The `}<inlineCode parentName="p">{`jwks`}</inlineCode>{` argument
should be the name of a file. The file contents should be a JWK Set or a JWE
with a JWK Set payload. The `}<strong parentName="p">{`--jwks`}</strong>{` flag requires the use of the `}<strong parentName="p">{`--kid`}</strong>{`
flag to specify which key to use.`}</p>
    <p><strong parentName="p">{`--kid`}</strong>{`=`}<inlineCode parentName="p">{`kid`}</inlineCode>{`
The ID of the key used to sign the JWT. The `}<inlineCode parentName="p">{`kid`}</inlineCode>{` argument is a case-sensitive
string. When used with '--jwk' the `}<inlineCode parentName="p">{`kid`}</inlineCode>{` value must match the `}<strong parentName="p">{`"kid"`}</strong>{` member
of the JWK. When used with `}<strong parentName="p">{`--jwks`}</strong>{` (a JWK Set) the `}<inlineCode parentName="p">{`kid`}</inlineCode>{` value must match
the `}<strong parentName="p">{`"kid"`}</strong>{` member of one of the JWKs in the JWK Set.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to decrypt the key.`}</p>
    <p><strong parentName="p">{`--x5c-cert`}</strong>{`=`}<inlineCode parentName="p">{`chain`}</inlineCode>{`
Certificate (`}<inlineCode parentName="p">{`chain`}</inlineCode>{`) in PEM format to store in the 'x5c' header of a JWT.`}</p>
    <p><strong parentName="p">{`--x5t-cert`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Certificate `}<inlineCode parentName="p">{`file`}</inlineCode>{` in PEM format to use for the 'x5t' header of a JWS or JWT`}</p>
    <p><strong parentName="p">{`--x5c-insecure`}</strong>{`
Use the JWT header 'x5cInsecure' instead of 'x5c'.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      